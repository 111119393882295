// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// banner
// =========================================================================
.banner-section {
	position: relative;
	height: 50vh;
	min-height: 270px;
	max-height: 540px;

	@include media-breakpoint-up(xl) {
		height: 75vh;
		min-height: 540px;
		max-height: 1080px;
	}

	.owl-carousel {
		.item {
			height: 50vh;
			min-height: 270px;
			max-height: 540px;

			@include media-breakpoint-up(xl) {
				height: 75vh;
				min-height: 540px;
				max-height: 1080px;
			}

			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}

		.owl-dots {
			display: none;
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	z-index: 100;
	position: relative;
	min-height: 25vh;
	padding-bottom: 6vh;

	.container-one-column {
		.column {
			margin-top: -6vw;
			margin-bottom: 15px;

			@include media-breakpoint-between(md, lg) {
				margin-bottom: 30px;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -161px;
				margin-bottom: 30px;
			}
		}
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		margin-bottom: -1px;
		background-repeat: no-repeat;
		background-position: center;

		@include media-breakpoint-down(lg) {
			aspect-ratio: 1366/103;
			background-size: 100%;
		}

		@include media-breakpoint-up(xl) {
			height: 161px;
			background-size: 3060px 161px;
		}
	}

	&.bg-blue {
		&::before {
			background-image: url('/images/wave-blue.svg');

			@include media-breakpoint-up(xl) {
				background-image: url('/images/curve-blue.svg');
				background-position: 8%;
			}
		}

		&.blue-2 {
			&::before {
				@include media-breakpoint-up(xl) {
					background-position: 40%;
				}
			}

			.container-one-column {
				.column {
					@include media-breakpoint-up(xl) {
						margin-top: -120px;
					}
				}
			}
		}

		&.blue-3 {
			&::before {
				@include media-breakpoint-up(xl) {
					background-position: 13%;
				}
			}
		}
	}

	&.bg-purple {
		&::before {
			background-image: url('/images/wave-purple.svg');

			@include media-breakpoint-up(xl) {
				background-image: url('/images/curve-purple.svg');
				background-position: 73%;
			}
		}

		&.purple-1 {
			.container-one-column {
				.column {
					@include media-breakpoint-up(xl) {
						margin-top: -120px;
					}
				}
			}
		}

		&.purple-2 {
			&::before {
				@include media-breakpoint-up(xl) {
					background-position: 94%;
				}
			}
		}

		&.purple-3 {
			&::before {
				@include media-breakpoint-up(xl) {
					background-image: url('/images/curve-purple-flipped.svg');
					background-position: 38%;
				}
			}

			.container-one-column {
				.column {
					@include media-breakpoint-up(xl) {
						margin-top: -120px;
					}
				}
			}
		}
	}
}

// mini-sab
// =========================================================================
.mini-sab {
	background: $blue;

	.container {
		display: flex;
		justify-content: center;
	}

	// .mini-search-book {
	// 	padding: 15px;
	// 	background-color: $purple;
	// 	border-radius: 10px;

	// 	@include media-breakpoint-up(xl) {
	// 		padding: 15px 40px 30px 40px;
	// 	}
	// }
}

// lead-section
// =========================================================================
&:not(.accommodation-detail) {
	.lead-section {
		position: relative;
		z-index: 100;
		padding-bottom: 6vh;
		@extend .bg-blue;

		// bundles
		.info-nav {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .align-items-center;
			@extend .justify-content-between;
			margin-bottom: 30px;
		}

		.info {

			// accommodation
			.properties {
				@extend .d-flex;
				@extend .flex-wrap;
				@extend .list-unstyled;

				li {
					margin-right: 15px;
				}

				.icon {
					margin-right: 5px;
				}
			}

			// assortiment
			.former-price {
				font-size: $font-size-sm;
				text-decoration: line-through;
			}

			.discount-price {
				font-size: $font-size-sm;

				.amount {
					font-size: $font-size-lg;
				}

				margin-bottom: 1rem;
			}

			.price {
				margin-bottom: 1rem;

				.amount {
					font-size: $font-size-lg;
				}
			}
		}
	}
}

&.entrance-page {
	.lead-section {
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100%;
			margin-bottom: -1px;
			background-repeat: no-repeat;
			background-position: center;

			@include media-breakpoint-down(lg) {
				aspect-ratio: 1366/103;
				background-image: url('/images/wave-blue.svg');
				background-size: 100%;
			}

			@include media-breakpoint-up(xl) {
				height: 161px;
				background-image: url('/images/curve-blue.svg');
				background-size: 3060px 161px;
				background-position: 8%;
			}
		}

		.container-one-column {
			.column {
				@include media-breakpoint-up(xl) {
					margin-top: -161px;
				}
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;
}

// news-section
// =========================================================================
.news-section {
	padding: 6vh 0;
	background: $blue;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		&.slider {
			.owl-dots {
				.owl-dot {
					span {
						border-color: $white;
					}

					&:hover,
					&.active {
						span {
							background: $white;
						}
					}
				}
			}
		}
	}
}

// media-section
// =========================================================================
.media-section {
	margin: 8vh 0;

	.container-one-column {
		.image-collection {
			.gallery-grid {
				.gallery-item {
					@include make-col(6);

					.gallery-link {
						border-radius: 10px;

						.gallery-caption {
							display: none;
						}
					}
				}
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					padding-right: 15px !important;
				}

				&.two {
					padding-left: 15px !important;
				}

				iframe,
				img {
					border-radius: 10px;
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	margin: 8vh 0;

	.container {
		max-width: 1110px;
		@extend .bg-blue;
		background-color: $red !important;
		border-radius: 10px;
		box-shadow: 3px 3px 10px rgba($black, 0.15);

		.column {
			max-width: 750px !important;
			margin: 6vh auto;

			h3 {
				margin-bottom: 30px;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 8vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(sm) {
						@include make-col(6);
					}

					.card {
						background-color: $red;
						border: none;
						border-radius: 10px;
						box-shadow: 3px 3px 10px rgba($black, 0.15);

						.card-body {
							@include media-breakpoint-up(xl) {
								padding: 30px;
							}

							.card-caption {
								color: $white;

								.card-title {
									&:hover {
										color: inherit;
									}
								}
							}

							.card-buttons {
								.card-btn {
									@extend .btn-white;
								}
							}
						}

						.card-image+.card-body {
							display: none !important;
						}

						&:hover {
							background-color: lighten($red, 15%);
						}
					}
				}
			}
		}
	}
}

// faq-section
// =========================================================================
.faq-section {
	margin: 8vh 0;
}

// cta-section
// =========================================================================
.cta-section {
	padding: 8vh 0;
	@extend .bg-purple;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: 8vh;
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 8vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 8vh 0;
}
