// footer
.footer {
	background-color: $white;

	// footer-sitelinks
	.footer-sitelinks {
		padding: 6vh 0;
		font-size: 16px;

		.container-holder {
			margin-bottom: -30px;

			.column {
				margin-bottom: 30px;
			}
		}

		.footer-text {
			h6 {
				font-size: 16px;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					color: $blue;

					a {
						color: $blue;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.footer-logo {
			margin-bottom: 1rem;
		}

		.footer-logolink {
			ul {
				align-items: center;

				li {
					+li {
						margin-left: 5px;
					}

					a {
						display: block;
					}

					&:first-of-type {
						a {
							color: $blue;
							font-size: 24px;
							line-height: 1;

							&:hover {
								color: $blue-dark;
							}
						}
					}

					&:not(:first-of-type) {
						a {
							width: 24px;
							height: 24px;
							background-color: $blue;
							border-radius: 100%;
							color: $white;
							font-size: 16px;
							line-height: 24px;
							text-align: center;

							&:hover {
								background-color: $blue-dark;
							}
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 15px 0;

		@include media-breakpoint-up(xl) {
			padding-bottom: 45px;
		}

		border-top: 1px solid $purple;
		font-size: 14px;

		a {
			font-weight: 700;
		}

		.container-holder {
			@include media-breakpoint-up(xl) {
				padding-right: 15%;
			}
		}
	}
}
