// btn
.btn {
	position: relative;
	padding: 15px 30px;
	border-radius: 6px;
	font-size: 18px;
	line-height: 20px;
	font-weight: 700;
	letter-spacing: 1.8px;

	&::after {
		content: "";
		position: absolute;
		right: -6px;
		bottom: -6px;
		width: 100%;
		height: 100%;
		border: 1px solid transparent;
		border-radius: 6px;
		transition: 0.5s;
	}

	// btn-primary
	&.btn-primary {
		border-color: $primary;

		&::after {
			border-color: $primary;
		}

		&:hover {
			border-color: $blue-dark;
			background-color: $blue-dark;

			&::after {
				border-color: $blue-dark;
			}
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		border-color: $primary;

		&::after {
			border-color: $primary;
		}

		&:hover {
			border-color: $blue-dark;
			background-color: $blue-dark;

			&::after {
				border-color: $blue-dark;
			}
		}
	}

	// btn-secondary
	&.btn-secondary {
		border-color: $secondary;

		&::after {
			border-color: $secondary;
		}

		&:hover {
			border-color: $blue-dark;
			background-color: $blue-dark;

			&::after {
				border-color: $blue-dark;
			}
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		border-color: $secondary;

		&::after {
			border-color: $secondary;
		}

		&:hover {
			border-color: $blue-dark;
			background-color: $blue-dark;

			&::after {
				border-color: $blue-dark;
			}
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	color: $blue !important;
	text-decoration: none !important;

	&:hover {
		color: $white !important;
	}
}

// btn-white
.btn-white {
	border-color: $white;
	color: $body-color;

	&::after {
		border-color: $white;
	}
}
