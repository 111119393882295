.bg-blue {
	background: $blue;

	.btn:not(.card-btn) {
		color: $blue;
	}
}

.bg-purple {
	background: $purple;

	.btn:not(.card-btn) {
		color: $purple;
	}
}

.bg-blue,
.bg-purple {
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $white;
	}

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
		color: $white;
		text-decoration: underline;

		&:hover {
			color: darken($text-light, 50%);
		}
	}

	// btn
	.btn:not(.card-btn) {
		background-color: $white;
		border-color: $white;

		&::after {
			border-color: $white;
		}

		&:active:focus,
		&:hover {
			border-color: $red;
			background-color: $red;
			color: $white;

			&::after {
				border-color: $red;
			}
		}
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $white;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {

				.owl-prev,
				.owl-next {
					color: $white;

					&:hover {
						color: darken($white, 50%);
					}
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						border-color: $white;
					}

					&:hover,
					&.active {
						span {
							background: $white;
						}
					}
				}
			}
		}
	}
}
