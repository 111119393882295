& {
	font-size: 18px;
}

// container
.container {
	&.container-medium {
		max-width: 750px;
	}

	&.container-large {
		max-width: 960px;
	}
}

// headings
h1 {
	font-family: $font-family-secondary;

	@include media-breakpoint-up(xl) {
		font-size: 80px;
	}
}

&.home,
&.entrance-page {
	h1 {
		@include media-breakpoint-up(xl) {
			font-size: 100px;
		}
	}
}

h2 {
	font-weight: 400;
}

h3 {
	font-family: $font-family-secondary;

}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;
	background-color: $red;
	border-radius: 10px;
	box-shadow: 3px 3px 10px rgba($black, 0.15);
	color: $white;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	a:not(.btn) {
		color: $white;

		&:hover {
			color: $black;
		}
	}

	.btn {
		@extend .btn-white;
	}
}

// owl-carousel
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
	font-size: 1.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 10px;
	box-shadow: 3px 3px 10px rgba($black, 0.15);

	.card-body {
		padding-bottom: 20px;

		.card-caption {
			.card-title {
				font-size: 24px;
			}

			.card-subtitle {
				font-size: 20px;
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
		}
	}
}

// heading-mage
.heading-mega {
	@include font-size($h1-font-size);
	font-weight: 700;
	font-family: $font-family-secondary;

	@include media-breakpoint-up(xl) {
		font-size: 100px;
	}
}

// faq-category
.page-block.faq-category {
	.faq-overview {
		margin: 15px 0 6vh 0;
		border-top: 1px solid $purple;

		.faq-category {
			.faq-items {
				.faq-item {
					border-bottom: 1px solid $purple;

					.faq-question {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 10px 0;
						cursor: pointer;

						a {
							display: none;
						}

						.faq-category {
							h3 {
								margin-bottom: 0;
								font-size: 20px;
								font-weight: 400;
								font-family: $font-family-primary;
								transition: 0.5s;

								@include media-breakpoint-up(xl) {
									font-size: 30px;
								}
							}
						}

						.faq-icon {
							color: $blue;
							font-size: 20px;

							@include media-breakpoint-up(xl) {
								font-size: 24px;
							}

							i {
								&::before {
									content: "\f067";
									font-weight: 400;
									transition: 0.5s;
								}
							}
						}

						&:hover {
							.faq-category {
								h3 {
									color: $blue;
								}
							}

							.faq-icon {
								color: $purple;
							}
						}
					}

					.faq-answer {
						display: none;
					}

					&.active {
						.faq-question {
							.faq-icon {
								i {
									&::before {
										content: "\f068";
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// search
&.search {
	.content-section {
		.searchbox {
			.search-icon {
				display: none;
			}
		}

		.search_alert {
			margin-top: 1rem;
		}

		.search_term {
			margin-top: 1rem;

			h3 {
				font-size: $font-size-base;
			}
		}
	}
}
