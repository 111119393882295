// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 26px;

		&::before {
			content: '\f178';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			font-weight: 400;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 27px;
		font-size: 20px;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
			padding: 0 0 0 31px;
		}

		&::before {
			content: '\f178';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			font-weight: 400;
		}
	}
}
