// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #1072af;
$blue-light: #f4f8fb;
$blue-dark: #1b2f70;
$purple: #424669;
$red: #d55452;
$white: #fff;
$black: #000;

// theme-colors
$primary: $blue;
$secondary: $red;

// body
$body-bg: $blue-light;
$body-color: $purple;

// links
$link-color: $blue;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=PT+Sans:wght@400;700&display=swap');
$font-family-primary: 'PT Sans', sans-serif;
$font-family-secondary: 'Merriweather', serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 5; // 80px
$h2-font-size: $font-size-base * 3; // 48px
$h3-font-size: $font-size-base * 2.25; // 36px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-margin-bottom: 1rem;
$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-color: $purple;

// contextual
$light: $white;
$dark: $blue-dark;
$text-light: $white;
$text-dark: $purple;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
