// menu-overlay
.menu-overlay {
	background: $blue-light;

	&.collapsing {
		transition: height 0.5s ease;
	}

	.container-fluid {
		height: calc(100vh - 117px);

		@include media-breakpoint-only(lg) {
			height: calc(100vh - 55px);
		}

		@include media-breakpoint-up(xl) {
			height: calc(100vh - 70px);
		}

		.container-holder {
			height: 100%;
			overflow: hidden;
			overflow-y: auto;
		}
	}

	// menu
	.menu {
		display: flex;
		align-items: flex-start;

		@include media-breakpoint-down(lg) {
			padding-top: 15px;

			.navbar-nav {
				width: 100%;

				>.nav-item {
					margin-bottom: 20px;

					>.nav-link {
						display: flex;
						flex-direction: row;
						padding: 0;
						color: $blue-dark;
						font-size: 18px;
						font-weight: 700;
						letter-spacing: 1.8px;
						transition: color 0.5s;

						.menu-icon {
							width: 32px;
							height: 32px;
							margin-right: 15px;
							background-color: $blue-dark;
							border-radius: 100%;
							color: $white;
							font-size: 18px;
							text-align: center;
							transition: 0.5s;
							overflow: hidden;

							img {
								max-width: 24px;
								max-height: 24px;
							}
						}

						.menu-title {
							border-bottom: 1px solid $blue-dark;
						}

						&:hover {
							color: $blue;

							.menu-icon {
								background-color: $blue;
							}

							.menu-title {
								border-color: $blue;
							}
						}

						&::after {
							display: none;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;

							.menu-icon {
								background-color: $blue;
							}

							.menu-title {
								border-color: $blue;
							}
						}
					}

					>.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 47px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						>.nav-item:not(.last) {
							font-size: 20px;

							>.nav-link {
								padding: 0;
								background: none;
								color: $blue-dark;
								pointer-events: none;

								&:hover {
									color: $blue-dark;
								}
							}

							&.active {
								>.nav-link {
									color: $blue-dark;
								}
							}
						}

						>.nav-item {
							margin-top: 20px;

							&.last {
								margin-bottom: 20px;

								>.nav-link {
									position: relative;
									display: inline-block;
									padding: 15px 30px;
									background-color: $blue;
									border-color: $blue;
									border-radius: 6px;
									color: $white;
									font-size: 18px;
									line-height: 20px;
									font-weight: 700;
									letter-spacing: 1.8px;

									&::after {
										content: "";
										position: absolute;
										right: -6px;
										bottom: -6px;
										width: 100%;
										height: 100%;
										border: 1px solid $blue;
										border-radius: 6px;
										transition: 0.5s;
									}

									&:hover {
										border-color: $blue-dark;
										background-color: $blue-dark;

										&::after {
											border-color: $blue-dark;
										}
									}
								}
							}
						}

						.dropdown-menu {
							display: block !important;
							transform: none !important;
							margin: 0;
							padding: 0;
							border: none;
							box-shadow: none;
							background: none;

							.nav-item {
								.nav-link {
									position: relative;
									padding: 0.15rem 0 0.15rem 25px;
									color: $blue;
									font-size: 16px;

									&:hover {
										color: $blue-dark;
									}

									&::before {
										content: '\f178';
										position: absolute;
										top: 0.15rem;
										left: 0;
										font-size: 18px;
										font-family: $font-awesome;
									}
								}

								&.active {
									.nav-link {
										color: $blue-dark;
									}
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			min-height: 500px;
			padding-top: 6vh;
			justify-content: center;

			.navbar-nav {
				position: relative;
				flex-direction: row;

				>.nav-item {
					position: static;
					margin: 0 25px;

					>.nav-link {
						display: flex;
						flex-direction: column;
						padding: 0;
						color: $blue-dark;
						font-size: 18px;
						font-weight: 700;
						letter-spacing: 1.8px;
						border-bottom: 1px solid $blue-dark;
						transition: color 0.5s;

						.menu-icon {
							width: 40px;
							height: 40px;
							margin: 0 auto 2px auto;
							background-color: $blue-dark;
							border-radius: 100%;
							color: $white;
							font-size: 24px;
							text-align: center;
							transition: 0.5s;
							overflow: hidden;
						}

						&:hover {
							color: $blue;
							border-color: $blue;

							.menu-icon {
								background-color: $blue;
							}
						}

						&::after {
							display: none;
						}
					}

					&.show {
						>.nav-link {
							color: $blue;
							border-color: $blue;

							.menu-icon {
								background-color: $blue;
							}
						}
					}

					&.active {
						>.dropdown-menu {
							display: flex;
						}
					}

					&.show {
						>.dropdown-menu {
							z-index: 1001;
						}
					}

					>.dropdown-menu {
						position: absolute;
						flex-flow: row wrap;
						align-items: flex-start;
						justify-content: space-between;
						top: 100%;
						left: 25px !important;
						right: 25px;
						transform: none !important;
						min-height: 300px;
						margin: 50px -15px 0 -15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: $blue-light;

						&.show {
							display: flex;
						}

						&::before,
						&::after {
							display: none;
						}

						>.nav-item:not(.last) {
							margin: 0 15px;
							font-size: 24px;

							>.nav-link {
								padding: 0;
								background: none;
								color: $blue-dark;
								pointer-events: none;

								&:hover {
									color: $blue-dark;
								}
							}

							&.active {
								>.nav-link {
									color: $blue-dark;
								}
							}
						}

						>.nav-item {
							&.last {
								display: flex;
								flex-basis: 100%;
								justify-content: center;
								margin-top: 50px;

								>.nav-link {
									position: relative;
									padding: 15px 30px;
									background-color: $blue;
									border-color: $blue;
									border-radius: 6px;
									color: $white;
									font-size: 18px;
									line-height: 20px;
									font-weight: 700;
									letter-spacing: 1.8px;

									&::after {
										content: "";
										position: absolute;
										right: -6px;
										bottom: -6px;
										width: 100%;
										height: 100%;
										border: 1px solid $blue;
										border-radius: 6px;
										transition: 0.5s;
									}

									&:hover {
										border-color: $blue-dark;
										background-color: $blue-dark;

										&::after {
											border-color: $blue-dark;
										}
									}
								}
							}
						}

						.dropdown-menu {
							display: block !important;
							transform: none !important;
							padding: 0;
							border: none;
							box-shadow: none;
							background: none;

							.nav-item {
								.nav-link {
									position: relative;
									padding: 0.15rem 0 0.15rem 25px;
									color: $blue;
									font-size: 16px;

									&:hover {
										color: $red;
									}

									&::before {
										content: '\f178';
										position: absolute;
										top: 0.15rem;
										left: 0;
										font-size: 18px;
										font-family: $font-awesome;
									}
								}

								&.active {
									.nav-link {
										color: $red;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// global-address
	div.global-address {
		margin-top: auto;
		padding: 30px 0;

		ul {
			@include media-breakpoint-up(xl) {
				align-items: center;
				justify-content: center;
			}

			li {
				@include media-breakpoint-down(lg) {
					width: 100%;
				}

				color: $blue-dark;
				font-size: 14px;
				font-weight: 700;

				@include media-breakpoint-up(xl) {
					+li {
						margin-left: 20px;
					}
				}

				&:nth-last-child(-n+2) {
					@include media-breakpoint-down(lg) {
						width: 24px;
					}

					font-size: 24px;

					span {
						display: none;
					}
				}

				&:last-of-type {
					margin-left: 5px;
				}

				a {
					color: $blue;
					text-decoration: underline;

					&:hover {
						color: $blue-dark;
					}
				}
			}
		}
	}
}
