// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 600px;
}

.eyecatcher {
	position: relative;

	&::after {
		content: "";
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: -1px;
		background-repeat: no-repeat;

		@include media-breakpoint-down(lg) {
			aspect-ratio: 1366/103;
			background-image: url('/images/wave-blue.svg');
			background-position: center;
			background-size: 100%;
		}

		@include media-breakpoint-up(xl) {
			height: 161px;
			background-image: url('/images/curve-blue.svg');
			background-position: 8%;
			background-size: 3060px 161px;
		}
	}

	.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.owl-dots {
			display: none;
		}
	}
}

&.entrance-page {
	.eyecatcher {
		&::after {
			display: none;
		}
	}
}
