// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $blue-dark;

	.header-top {
		z-index: 1;
		position: relative;
		box-shadow: $shadow;

		.container-fluid {
			.container-holder {
				.column {
					flex-flow: row wrap;
					align-items: center;
					min-height: 55px;
					padding-left: 0;
					padding-right: 0;

					@include media-breakpoint-up(xl) {
						padding-right: 25px;
						min-height: 70px;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		align-self: stretch;

		.navbar-toggler {
			display: flex;
			align-items: center;
			height: 100%;
			margin-right: 15px;
			padding: 0 15px;
			background-color: $white;
			border: none;
			border-radius: 0;
			color: $blue;
			font-size: 18px;
			font-weight: 700;
			transition: 0.5s;

			@include media-breakpoint-up(xl) {
				margin-right: 20px;
				padding: 0 30px;
				font-size: 20px;
			}

			.navbar-toggler-label {
				margin-left: 10px;

				@include media-breakpoint-up(xl) {
					margin-left: 20px;
				}

				@include media-breakpoint-down(xs) {
					display: none;
				}
			}

			i {
				width: 24.5px;
				font-size: 28px;
				font-weight: 400;
				text-align: center;

				@include media-breakpoint-up(xl) {
					width: 31.5px;
					font-size: 36px;
				}

			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background-color: $blue;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		width: 160px;
		margin: 10px auto 10px 0;

		@include media-breakpoint-up(xl) {
			width: 239px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// searchbox
	.searchbox {
		position: relative;
		margin-right: 15px;
		margin-left: auto;

		@include media-breakpoint-up(xl) {
			margin-right: 20px;
		}

		.form-control {
			display: none;
			position: absolute;
			right: 35px;
			width: 200px;
			transition: $transition-base;

			&.show {
				display: block;
			}
		}

		.search-icon {
			display: block;
			color: $white;
			font-size: 20px;
			text-align: center;
			transition: $transition-base;
			cursor: pointer;

			@include media-breakpoint-up(xl) {
				font-size: 24px;
			}

			i {
				font-weight: 400;
			}

			&.hide {
				//display: none;
			}

			&:hover {
				color: $blue;
			}
		}
	}

	// quick-access
	div.quick-access {
		@include media-breakpoint-down(md) {
			order: 5;
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			margin-left: auto;
			margin-right: 30px;
		}

		ul {
			flex-wrap: nowrap !important;
			column-gap: 15px;

			@include media-breakpoint-down(md) {
				flex-wrap: wrap !important;
				justify-content: center;
				padding: 15px;
			}

			@include media-breakpoint-down(sm) {
				column-gap: 0;
				row-gap: 15px;
			}

			li {

				@include media-breakpoint-down(sm) {
					text-align: center;
					flex: 0 0 50%;
					max-width: 50%;
					padding: 0 4px;

					&:last-child {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}

				a {
					@extend .btn;
					border-color: $red;
					background-color: $red;
					padding: 7px !important;
					color: $white;
					font-size: 16px !important;
					width: 100%;

					@include media-breakpoint-up(lg) {
						padding: 7px 15px !important;
					}

					&::after {
						border-color: $red !important;
					}

					&:hover {
						border-color: $white;
						background-color: $white;
						color: $blue-dark;

						&::after {
							border-color: $white !important;
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		// margin-left: auto;
		margin-right: 15px;

		ul {
			li {
				a {
					border-color: $white !important;

					&:hover {
						border-color: $blue !important;
					}
				}
			}
		}
	}
}
